<template>
  <v-dialog v-model="dialog" max-width="800px" @click:outside="onClose">
    <v-card color="grey lighten-2" tile>
      <v-app-bar absolute flat color="white">
        <v-toolbar color="primary" dark dense>
          <v-toolbar-title>Link to Project</v-toolbar-title>
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="onClose" v-on="on" data-test="close-button">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close this window</span>
          </v-tooltip>
        </v-toolbar>
      </v-app-bar>
      <v-sheet id="scroll-body" class="scroller px-5">
        <v-container fluid class="mt-16">
          <v-row align="center">
            <v-col cols="12">
              <v-row dense class="mt-2">
                <v-col cols="12" md="8">
                  <v-text-field
                    dense
                    label="Project Name"
                    name="projectname"
                    type="text"
                    autocomplete="off"
                    autofocus
                    focus
                    clearable
                    hide-details
                    :disabled="!!part_number"
                    v-model="project_name"
                    @click:clear="project_list.splice(0)"
                    @keypress.enter.stop="searchProject"
                    data-test="project-name"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    block
                    :disabled="!project_name"
                    color="secondary"
                    @click="searchProject"
                    data-test="search-project"
                    >Search</v-btn
                  >
                </v-col>
              </v-row>
              <v-row dense class="mt-10">
                <v-col cols="12" md="8">
                  <v-text-field
                    dense
                    label="Fixture Design SKU"
                    name="partnumber"
                    type="text"
                    autocomplete="off"
                    clearable
                    hide-details
                    :disabled="!!project_name"
                    v-model="part_number"
                    @click:clear="project_list.splice(0)"
                    @keypress.enter.stop="searchFixture"
                    data-test="part-number"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    block
                    :disabled="!part_number"
                    color="secondary"
                    @click="searchFixture"
                    data-test="search-fixture"
                    >Search</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-list-item three-line class="ml-n4">
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 info--text"
                >Note - Searching a SKU only searches the four middle digits of
                a full_part_number.</v-list-item-title
              >
              <v-list-item-subtitle class="info--text">
                - A full part number is 601-0332-00, but only the 0322 digits
                are searchable.
              </v-list-item-subtitle>
              <v-list-item-subtitle class="info--text">
                - 0332 will work, 601 will not. 033 will also work.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <div v-if="project_list.length > 0" class="mt-5" data-test="div-list">
            <v-divider />
            <v-row dense v-for="(project, index) in project_list" :key="index">
              <v-col cols="12">
                <v-card
                  x-large
                  block
                  class="pa-5 mt-1 text-center moduleLink"
                  @click="
                    onSaveFixtureToQuote({
                      quote: quoteId,
                      fixtureDesign: project.fixture_design,
                      projectName: project.project_name,
                    })
                  "
                  :data-test="`project-button${index}`"
                >
                  <v-row dense class="text-center">
                    <v-col cols="12">
                      <span class="body-1">{{
                        `Project(${project.project_pk}) - ${project.project_name}`
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-row dense class="text-center">
                    <v-col cols="12">
                      <span class="body-1">{{
                        `Fixture Design (${project.fixture_design}) - ${project.part_number}`
                      }}</span>
                    </v-col>
                  </v-row></v-card
                >
              </v-col>
            </v-row>
          </div>
          <div v-else class="mt-5" data-test="div-nolist">
            <v-divider />
            <v-card color="grey lighten-2" flat>
              <v-card-text class="body-2 font-weight-bold text-center"
                >No items to display</v-card-text
              >
            </v-card>
          </div>
        </v-container>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "SearchProjectModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    quoteId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      project_name: null,
      part_number: null,
      project_list: [],
    };
  },
  methods: {
    onClose() {
      this.project_name = this.part_number = null;
      this.project_list.splice(0);
      this.$emit("closeProjectModal");
    },
    async onSearch() {
      if (this.project_name) {
        this.searchProject();
      } else {
        this.searchFixture();
      }
    },
    async searchProject() {
      if (!this.project_name) return;
      try {
        const response = await this.$store.dispatch("projects/searchProjects", {
          params: { search: this.project_name },
        });
        this.project_list = response.map((element) => ({
          project_pk: element.pk,
          project_name: element.project_name,
          fixture_design: Object.keys(element.fixture_design_details)[0],
          part_number: Object.values(element.fixture_design_details)[0]
            ?.full_part_number,
          quote: Object.values(element.fixture_design_details)[0]?.fd_quote_pk,
        }));
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async searchFixture() {
      if (!this.part_number) return;
      try {
        const response = await this.$store.dispatch(
          "fixturedesign/searchFixtures",
          {
            params: { search: this.part_number },
          }
        );
        this.project_list = response.map((element) => ({
          fixture_design: element.pk,
          part_number: element.part?.number,
          project_pk: Object.keys(element.project_details)[0],
          project_name: Object.values(element.project_details)[0]?.project_name,
        }));
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async onSaveFixtureToQuote(payload) {
      if (payload.fixtureDesign.length == 0) return;
      this.$emit("saveFixture", payload);
    },
    initLocalData() {
      this.project_name = this.part_number = null;
      this.project_list.splice(0);
    },
  },
};
</script>
<style scoped>
.scroller {
  height: calc(100vh - 150px);
  overflow-y: auto;
}
</style>
